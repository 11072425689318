@import './Mixin.sass'

#header
    padding: 1.5rem 0 1rem 0
    width: 100%
    display: grid
    place-items: center
    div
        width: 100%
        display: flex
        justify-content: space-between
        padding: 0 0 .5rem 0
        // border-bottom: 2px solid black
        @include mobile
            border-bottom: 0px
            padding: 0 0 .3rem 0
    img
        object-fit: contain
        &:nth-child(1)
            margin-left: 2rem
            width: 170px
            @include mobile
                width: 130px
        &:nth-child(2)
            margin-right: 2rem
            width: 50px

#home
    height: calc(100vh - 50px)
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-evenly
    *
        z-index: 3
    #thumb
        z-index: 1
        position: absolute
        object-fit: cover
        width: 100%
        height: 100%
    .video-wrapper
        z-index: 1
        position: absolute
        display: none
        video
            object-fit: cover
    .video-overlay
        background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, .4) 50%, transparent)
        width: 100%
        height: 100%
        z-index: 2
        position: absolute

    .acervo
        margin: 0
        font-size: 7rem
        color: white
        font-weight: 600
        letter-spacing: .4rem
    .frase
        font-size: 2rem
        font-weight: 400
        color: white
        font-weight: 400
        position: relative
        text-transform: uppercase
        transform: scaleY(0.95)
        letter-spacing: .2rem
        &::before
            content: ''
            width: 25%
            height: 100%
            border-top: 4px solid white
            position: absolute
            top: -10px
            left: 0px
            @include mobile
                width: 6%

    .filter-field
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        .find-text
            font-size: 1.5rem
            color: white
            font-weight: bold
    @media (max-width: 768px)
        .acervo
            font-size: 4rem
        .frase
            font-size: 1.5rem
        .filter-field
            width: 100%
            .find-text
                font-size: 1.2rem
