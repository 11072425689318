@import '../Mixin.sass'
.project
  padding: 0 120px
  margin-bottom: 10rem
  @include mobile
    padding: 0 20px
  @include laptop
    padding: 0 120px
  @include big
    padding: 0 20%
  *
    box-sizing: border-box
  .details
    margin-top: 60px
    display: flex
    @include mobile
      flex-direction: column

    .details__image
      width: 60%
      aspect-ratio: 3/3
      position: relative
      object-fit: cover
      padding: 0px 60px
      @include mobile
        width: 100%
        padding: 0
      @include laptop
        padding: 0 40px
      img
        width: 100%
        height: 100%
        object-fit: cover
        @include mobile
          width: 100%

    .details__info
      width: 40%
      @include mobile
        width: 100%
      h1
        font-size: 35px
        font-weight: 300
        text-transform: uppercase
        color: #282828
        @include mobile
          font-size: 25px
        @include laptop
          margin: 0
          font-size: 30px
      h2
        font-size: 20px
        font-weight: 300
        text-transform: uppercase
        @include mobile
          font-size: 12px
        @include laptop
          font-size: 18px
      ul
        margin-top: 48px
        padding-left: 20px
        @include laptop
          margin-top: 30px
        li
          margin-bottom: 20px
          font-size: 20px
          font-weight: 300
          @include mobile
            font-size: 12px
          @include laptop
            font-size: 16px

      .details__address
        margin-top: 40px
        display: flex
        align-items: center
        gap: 10px
        p
          margin: 0
          padding: 0
          font-size: 20px
          font-weight: 300
          @include mobile
            font-size: 12px
          @include laptop
            font-size: 16px

      button
        margin-top: 40px

  .description
    margin-top: 100px
    width: 70%
    @include mobile
      margin-top: 30px
      width: 100%
    @include laptop
      margin-top: 70px
    p
      font-size: 20px
      font-weight: 300
      color: #282828
      line-height: 1.5
      @include mobile
        font-size: 16px
      @include laptop
        font-size: 16px

  .video
    margin-top: 80px
    margin-bottom: 60px
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    @include mobile
      width: 100%
      margin-top: 40px
    @include laptop
      margin-top: 50px
    video
      width: 100%
      @include mobile
        height: 200px
    iframe
      width: 100%
      @include mobile
        height: 200px
    img
      width: 100%
      @include mobile
        width: 100%
        height: auto
    button
      margin-top: 40px
      background: black
      border-radius: 30px
      border: none
      color: white
      width: fit-content
      font-size: 20px
      padding: 10px 40px
      cursor: pointer

  .about
    margin-top: 60px
    margin-bottom: 60px
    @include mobile
      margin: 40px 0
    h1
      font-size: 35px
      font-weight: 400
      color: #282828
      text-transform: uppercase
      @include mobile
        font-size: 20px
      @include laptop
        font-size: 30px
    p
      margin: 0
      padding: 0
      color: #282828
      font-size: 20px
      font-weight: 400
      width: 80%
      line-height: 1.5
      @include mobile
        font-size: 12px
        width: 100%
      @include laptop
        font-size: 16px

  .gallery
    .gallery__images
      display: flex
      flex-wrap: wrap
      .gallery__wrapper
        width: 50%
        aspect-ratio: 73 / 40
        padding: 0 10px
        margin-bottom: 20px
        overflow: hidden
        align-items: center
        display: flex
        @include mobile
          width: 100%
        img
          width: 100%
          height: 100%
          object-fit: cover
    .gallery__button
      display: flex
      align-items: center
      justify-content: center
      gap: 10px
      background: white
      padding: 10px 40px
      border: 2px solid black
      border-radius: 30px
      cursor: pointer
      transition: all 0.2s ease
      &:hover
        background: black
        color: white
        img
          filter: invert(1)
      p
        margin: 0
        font-size: 18px
  .arch
    margin-top: 60px
    margin-bottom: 60px
    display: flex
    @include mobile
      margin-top: 20px
      flex-direction: column
    .arch__text
      width: 50%
      @include mobile
        width: 100%
      h1
        font-size: 35px
        font-weight: 400
        text-transform: uppercase
        margin-bottom: 10px
        @include mobile
          font-size: 20px
        @include laptop
          font-size: 30px
      h2
        font-size: 20px
        font-weight: 400
        @include mobile
          font-size: 12px
        @include laptop
          font-size: 18px
      p
        margin-top: 60px
        width: 80%
        font-size: 20px
        font-weight: 400
        line-height: 1.5
        @include mobile
          margin-top: 30px
          font-size: 12px
          width: 100%
        @include laptop
          margin-top: 40px
          width: 90%
          line-height: 1.3
          font-size: 16px

    .arch__image
      width: 50%
      margin-top: 40px
      @include mobile
        margin: 0
        width: 100%
      img
        width: 100%
        height: auto

  .datasheet
    display: flex
    margin-bottom: 60px
    @include mobile
      flex-direction: column-reverse
    .datasheet__details
      width: 50%
      padding-right: 100px
      @include laptop
        padding-right: 40px
      @include mobile
        width: 100%
        padding: 0
        margin-top: 30px
      h1
        font-size: 35px
        font-weight: 400
        text-transform: uppercase
        color: #282828
        @include mobile
          font-size: 20px
          width: 50%
        @include laptop
          font-size: 30px
      ul
        @include mobile
          padding-left: 12px
        @include laptop
          padding-inline-start: 20px

        li
          font-size: 20px
          color: #282828
          font-weight: 300
          padding: 15px 0
          border-bottom: 1px solid rgba(96, 110, 117, 0.83)
          &:last-of-type
            border: none
          @include laptop
            font-size: 16px
          @include mobile
            font-size: 12px
            padding: 10px 0

    .datasheet__image
      width: 50%
      @include mobile
        width: 100%
      img
        width: 100%
        @include mobile
          width: 100%
          height: auto
