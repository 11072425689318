@import '../Mixin.sass'

button.contact
  margin-top: 40px
  background: black
  border-radius: 30px
  border: none
  color: white
  width: fit-content
  font-size: 20px
  padding: 10px 40px
  cursor: pointer
  @include mobile
    font-size: 12px !important
  @include laptop
    font-size: 16px !important