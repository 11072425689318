@import '../Mixin.sass'
.footer
  background: #7E878C
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 80px 0
  *
    margin: 0

  .creci
    margin-top: 2rem
    display: flex
    flex-direction: column
    align-items: center
    bottom: 10px
    right: 10px
    gap: .3rem
    text-decoration: none
    color: white
    font-size: .8rem

  .desenvolvido
    margin-top: 2rem
    display: flex
    flex-direction: column
    align-items: center
    bottom: 10px
    right: 10px
    gap: .3rem
    text-decoration: none
    color: black
    span
      font-size: 0.8rem

  .form
    width: 30%
    display: flex
    flex-direction: column
    align-items: center
    @include mobile
      width: 80%
    @include big
      width: 25%
    h2
      text-align: center
      margin-bottom: 20px
      color: white
      font-weight: 300
      font-size: 45px
      @include mobile
        font-size: 25px
    h3
      text-align: center
      margin-bottom: 30px
      color: white
      font-weight: 300
      font-size: 30px
      @include mobile
        font-size: 12px
    input
      background: #fbfbfbb9
      height: 50px
      width: 100%
      padding: 0 10px
      border: none
      border-radius: 6px
      margin-bottom: 30px

    button
      background: black
      color: #F4F4F4
      border-radius: 30px
      border: none
      width: fit-content
      padding: 10px 40px
      margin-left: auto
      margin-right: auto
      font-size: 20px
      font-weight: 400
      cursor: pointer
      @include mobile
        font-size: 12px
