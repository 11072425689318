@import '../styles/Mixin.sass'
.home
  padding: 0 100px
  @include mobile
    padding: 0 20px
  @include big
    padding: 0 10%
  .acervo
    display: flex
    // align-items: center
    margin-bottom: 80px
    flex-direction: column
    @include mobile
      flex-direction: column
      align-items: flex-start
    .text
      width: 9  0%
      padding-left: 30px
      @include mobile
        width: 100%
        padding-left: 0
      h2
        font-weight: lighter
        font-size: 40px
        color: #282828
        font-weight: 300
        @include mobile
          font-size: 25px
      p
        font-size: 20px
        font-weight: 400
        @include mobile
          font-size: 12px
        &.footer
          font-weight: 700
        
          
    .action
      width: 40%
      display: flex
      padding-left: 30px
      button
        background: black
        border-radius: 30px
        border: none
        color: white
        width: fit-content
        font-size: 20px
        padding: 10px 40px
        cursor: pointer
  
  .filtro
    button.filtrar
      background: none
      border: none
      text-decoration: underline
      cursor: pointer
      font-size: 16px
  
  .galeria
    @include big
      width: 90%
      margin-right: auto
      margin-left: auto
    .gallery
      // display: flex
      // flex-wrap: wrap
      // gap: 10px
      // justify-content: flex-start
      .location
        h3
          font-size: 40px
          font-weight: 300
          font-family: 'Inter', sans-serif
        .location_wrapper
          display: flex
          flex-wrap: wrap
          gap: 10px
      .column
        flex: 25%
        @include mobile
          flex: 100%




