@import '../Mixin.sass'
.card
  margin-bottom: 100px
  cursor: pointer
  @include mobile
    margin-bottom: 40px
  *
    margin: 0
    padding: 0
  a
    text-decoration: none
    color: #282828
    position: relative
  
  
  .cardimg
    display: flex
    width: 380px
    align-items: end
    flex-direction: column
    position: relative
    .imgnotsold, .imgsold
      width: 380px
      height: auto
      margin-bottom: 20px
      transition: all 0.3s ease-in-out
      filter: grayscale(0)
      &:hover
        transform: scale(1.05)
        filter: grayscale(0) brightness(1)
      @include mobile
        width: 100%
        height: auto
    @include mobile
      width: 100%

    .imgsold
      filter: grayscale(1) brightness(0.8)
      &:hover
        transform: scale(1.05)
        filter: grayscale(1) brightness(1)

    .spannotsold, .spansold
      font-family: Inter,sans-serif
      position: absolute
      top: 20px
      color: black
      display: inline-block
      width: auto
      height: 0
      border-bottom: 33px solid white
      border-left: 5px solid transparent
      border-right: 0
      padding-left: 10px
      padding-right: 10px
      font-weight: 500
      font-size: 18px
      letter-spacing: 1px
      line-height: 1.8
      transition: all 0.3s ease-in-out
    
    .spansold
      color: white
      border-bottom: 33px solid rgba(50,50,50,1)

    &:hover
      span
        transform: translateX(10px)

  h2
    font-weight: 100
    margin-bottom: 10px
    text-transform: capitalize
    @include mobile
      margin-bottom: 20px
  ul
    padding-left: 20px
    li
      font-weight: 300
      margin-bottom: 10px
      @include mobile
        font-size: 12px