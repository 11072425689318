@import "../Mixin.sass"

.cookies
  position: fixed
  bottom: 0
  width: 100%
  background: #E0DED5
  display: flex
  gap: 30px
  padding: 10px 40px
  align-items: center
  box-sizing: border-box
  @include mobile
    flex-direction: column
    padding: 10px 20px
    gap: 10px
  p
    width: 80%
    font-size: 14px
    color: #707072
    @include mobile
      width: 100%
      font-size: 12px
    span
      text-decoration: underline
      cursor: pointer
  button
    text-transform: uppercase
    border: none
    border-radius: 8px
    color: #FAF8F5
    background: #707072
    width: 300px
    height: 50px
    @include mobile
      font-size: 12px