$mobile-width: 430px
$tablet-width: 768px
$laptopS-width: 1440px
$laptop-width: 1900px

@mixin mobile
  @media (max-width: #{$tablet-width})
    @content

// @mixin tablet 
//   @media (min-width: #{$mobile-width+1}) and (max-width: #{$tablet-width}) 
//     @content

@mixin laptop
  @media (min-width: #{$tablet-width+1}) and (max-width: #{$laptopS-width - 1})
    @content

@mixin big
  @media (min-width: #{$laptop-width+1})
    @content
