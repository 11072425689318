@import '../Mixin.sass'

.nav__wrapper
  background-color: white
  padding: 0 40px
  @include mobile
    padding: 0 20px
nav.nav
  display: flex
  justify-content: space-between
  padding-top: 30px
  box-sizing: border-box
  width: 100%
  .logo
    width: 150px
    @include mobile
      width: 130px
  .bsd
    width: auto
    height: 10px
