@import "../Mixin.sass"
.modal
  position: fixed
  background: #3b3b3b2c
  display: none
  justify-content: center
  align-items: center
  height: 100%
  top: 0
  @include mobile
    width: 100%
  .modal-content
    border-radius: 5px
    background: white
    width: 60%
    height: 80%
    z-index: 2
    overflow-y: scroll
    @include mobile
      width: 90%
    .modal-header
      display: flex
      justify-content: space-between
      align-items: center
      padding: 0 20px
      border-bottom: 1px solid #ccc
      @include mobile
        padding: 0 10px
      p
        font-size: 30px
        @include mobile
          font-size: 20px
      span
        cursor: pointer
        font-weight: bold
    .modal-body
      display: flex
      flex-direction: column
      height: 90%
      padding: 50px
      padding-top: 20px
      @include mobile
        padding: 10px
      h1
        height: auto
        margin: 0    
        color: #3b3b3b
        font-size: 24px
        text-align: left
        margin-bottom: 30px
      ul
        text-align: left
        margin-bottom: 20px
        color: #3b3b3b
        font-size: 14px
      p
        color: #3b3b3b
        text-align: left
        margin-bottom: 20px
        font-size: 14px
        @include mobile
          font-size: 12px