@import '../Mixin.sass'
.filter
  display: flex
  flex-direction: row
  position: relative
  z-index: 999
  background: white
  transition: left 0.5s
  box-sizing: border-box
  padding: 10px
  border-radius: 50px
  &.open
    left: 0
  @include mobile
    width: 95%
    justify-content: center

  .body
    display: flex
    width: 100%
    justify-content: space-around
    h2
      margin: 0
      font-size: 20px
    .filtros
      display: flex
      flex-direction: row
      align-items: center
      @include mobile
        width: 100%
        justify-content: space-between
      .find-ico
        width: 40px
        @include mobile
          // display: none
      .button-search
        padding: 17px
        background-color: black
        border-radius: 50px
        color: white
        text-decoration: unset
        transition: all 0.2s ease-in-out
        cursor: pointer
        border: 0
        &:hover
          background-color: lightgray
          color: black

      .filter-option
        z-index: 5 !important
        margin: 0 2rem
        display: flex
        align-items: center
        justify-content: center
        height: 1.5rem
        position: relative
        width: 100%
        height: 30px

        &:hover
          .options
            display: block
          .arrow
            transform: rotateZ(180deg)
        @include mobile
          width: 25%
          margin: 0 .2rem
        p
          width: 100%
          margin: 0
          margin-right: 5px
          padding: 0
          color: black
          text-align: center
          @include mobile
            font-size: 14px
            text-decoration: underline
          &::after
            content: ''
        .arrow
          width: 15px
          transition: all 0.2s ease-in-out
          @include mobile
            display: none

        .options
          position: absolute
          top: 30px
          width: 200px
          background-color: white
          filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.2))
          padding: 10px
          border-radius: 5px
          transition: all 0.2s ease-in-out
          display: none
          &:hover
            display: block
          @include mobile
            width: 150%
      .checkbox__wrapper
        margin-bottom: 10px
        input[type="checkbox"]
          border-radius: 0
          height: 20px
          width: 20px
        label
          margin-left: 10px
          font-size: 16px
          @include mobile
            font-size: 12px
