@import "~react-image-gallery/styles/scss/image-gallery.scss"
@import '../Mixin.sass'
.gallery__background
  display: none
  background: #ccc
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 998
  opacity: 0.5
.gallery__component
  display: none
  position: fixed
  background: white
  flex-direction: column
  align-items: center
  justify-content: center
  width: 80%
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 999
  box-sizing: border-box
  text-align: center
  @include mobile
    width: 100%
    transform: translate(-50%, -70%)
  .image-gallery
    width: 100%
  .close__button
    position: absolute
    top: 45px
    right: 20px
    cursor: pointer
    width: 30px
    height: 30px